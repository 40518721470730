import { useStaticQuery, graphql } from 'gatsby';

const useNewslistBanner = () => {
    const {
        allSanityConfigGlobal: {
            nodes: [{ newslistNewsBanner: news }],
        },
    } = useStaticQuery(graphql`
        query newslistBanner {
            allSanityConfigGlobal {
                nodes {
                    newslistNewsBanner {
                        _createdAt
                        publishDate
                        _id
                        slug {
                            current
                        }
                        title
                        language {
                            hreflang
                        }
                        video {
                            teaserVideoPreview {
                                image {
                                    ...ImageWithPreview
                                }
                                alt
                            }
                            teaserVideo {
                                url
                            }
                        }
                        image {
                            defaultImage {
                                alt
                                image {
                                    hotspot {
                                        x
                                        y
                                    }
                                    asset {
                                        url
                                        _id
                                    }
                                }
                            }
                        }
                        category {
                            title {
                                en
                                se
                            }
                            ref
                        }
                    }
                }
            }
        }
    `);

    return news;
};

export default useNewslistBanner;
