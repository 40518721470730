import * as React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import useAboutPage from 'data/queries/useAboutPage';
import CustomLink from 'components/utils/CustomLink';
import FormattedMessage from 'components/utils/FormattedMessage';
import * as style from 'styles/components/about/preview.module.scss';

const AboutBlockPreview = () => {
    const data = useAboutPage();

    return (
        <div className={style.aboutPreview}>
            <div className={`${style.aboutPreview__content} wrapper`}>
                <div className={style.aboutPreview__left}>
                    <span className={`${style.aboutPreview__tag} text-xs text-medium uppercase`}>
                        Hammarby <FormattedMessage id="about.history" />
                    </span>
                    <h2
                        className={`${style.aboutPreview__title} title-sm-mobile title-lg text-semibold`}
                    >
                        {data.previewTitle}
                    </h2>
                    <CustomLink namedRoute="about" className="cta-white">
                        <FormattedMessage id="about.learnMore" />
                    </CustomLink>
                </div>
                <div className={style.aboutPreview__right}>
                    {data.previewImage[0] && (
                        <ImageGatsby
                            {...data.previewImage[0]}
                            alt={data.previewTitle}
                            className={style.aboutPreview__img}
                        />
                    )}
                    <span className={style.aboutPreview__year} data-text={data.previewYear}>
                        {data.previewYear}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AboutBlockPreview;
