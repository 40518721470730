import * as React from 'react';
import { Link } from 'gatsby';

import NewsItem from 'components/news/Item';
import FormattedMessage from 'components/utils/FormattedMessage';
import * as style from 'styles/components/news/blockListPaginated.module.scss';

const NewsBlockListPaginated = ({
    title,
    items,
    numberOfPages,
    previousPagePath,
    nextPagePath,
}) => (
    <div id="news" className={`${style.newsBlockList} wrapper block-paddings`}>
        <div className={style.newsBlockList__header}>
            {title &&
                <h2 className="title-sm title-xs-mobile text-semibold">{title}</h2>
            }

            {numberOfPages > 1 && (
                <div className={style.newsBlockList__pagination}>
                    <div className={`${style.newsBlockList__prev} ${
                        !previousPagePath ? style.newsBlockList__inactive : ''
                    }`}>
                        <Link to={previousPagePath && `/${previousPagePath}`}>
                            <i className="ico-chevron" aria-hidden="true" />
                            <span className="visually-hidden"><FormattedMessage id="global.prev"/></span>
                        </Link>
                    </div>
                    <div className={`${style.newsBlockList__next} ${
                        !nextPagePath ? style.newsBlockList__inactive : ''
                    }`}>
                        <Link to={nextPagePath && `/${nextPagePath}`}>
                            <i className="ico-chevron" aria-hidden="true" />
                            <span className="visually-hidden"><FormattedMessage id="global.next"/></span>
                        </Link>
                    </div>
                </div>
            )}
        </div>

        <ul>
            {items.map(item => (
                <li key={item._id}>
                    <NewsItem item={item} />
                </li>
            ))}
        </ul>
        <div className={style.newsBlockList__footer}>
            {numberOfPages > 1 && (
                <div className={style.newsBlockList__pagination}>
                    <div className={`${style.newsBlockList__prev} ${
                        !previousPagePath ? style.newsBlockList__inactive : ''
                    }`}>
                        <Link to={previousPagePath && `/${previousPagePath}`}>
                            <i className="ico-chevron" aria-hidden="true" />
                            <span className="visually-hidden"><FormattedMessage id="global.prev"/></span>
                        </Link>
                    </div>
                    <div className={`${style.newsBlockList__next} ${
                        !nextPagePath ? style.newsBlockList__inactive : ''
                    }`}>
                        <Link to={nextPagePath && `/${nextPagePath}`}>
                            <i className="ico-chevron" aria-hidden="true" />
                            <span className="visually-hidden"><FormattedMessage id="global.next"/></span>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    </div>
);

export default NewsBlockListPaginated;
