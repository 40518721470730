import React, { useRef, useState } from 'react';
import { navigate } from 'gatsby';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/search/searchBoxSuggest.module.scss';
import Loader from 'components/utils/Loader';
import getSearch from 'data/queries/getSearchResults';

const SearchBox = () => {

    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [q, setQ] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [isActiveInput, setIsActiveInput] = useState(false);
    const searchInput = useRef();

    const buildDate = (publishDate) => {
        const date = new Date(publishDate);
        const formattedDate = date.toLocaleDateString('sv-SE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

        return `${formattedDate}`;
    }

    const handleSearchSubmit = async (evt) => {
        evt.preventDefault();
        const searchQuery = searchInput.current?.value;
        if(searchQuery.length < 3) return;
        navigate(`/sok?q=${searchQuery}`);
    };

    const handleSearchChange = async (evt) => {
        evt.preventDefault();

        const searchQuery = searchInput.current?.value;
        if(searchQuery.length < 3) {
            setSearchResults([]);
            setShowResults(false);
            setIsActiveInput(false);
            setLoading(true);
            return;
        }
        setIsActiveInput(true);

        setShowResults(true);
        setQ(searchQuery);
        const results = await getSearch(searchQuery, 11);
        setSearchResults(results);
        if( results ) {
            setLoading(false);
        }
    }

    return (
        <div className={style.search__searchField}>
            <form method="GET" onSubmit={handleSearchSubmit}>
                <fieldset className={style.search__searchField__inputContainer}>

                    <input
                        type="text"
                        id="newsSearchField"
                        name="q"
                        placeholder="Sök"
                        ref={searchInput}
                        autoComplete="off"
                        onBlur={() => {
                            setTimeout(() => {
                                setIsActiveInput(false);
                            }, 200);
                        }}
                        onFocus={() => setIsActiveInput(true)}
                        onChange={handleSearchChange}
                    />
                </fieldset>
                <button type="submit" aria-label="Sök" className={style.search__searchField__submit}><i className="ico-search" aria-hidden="true" /></button>
            </form>
            {showResults && isActiveInput &&
                <div className={style.search__resultsWrapper}>
                    {loading &&
                        <div className={style.search__resultsLoader}>
                            <Loader />
                        </div>
                    }
                    {searchResults.length > 0 &&
                        <ul>
                            {searchResults.slice(0,10).map((searchResult, index) => (
                                <li key={index} className={style.search__resultItem}>
                                    <a href={`/nyheter/${searchResult.w3}`}>
                                        <div className={style.search__resultItem__image}>
                                            {searchResult.w7 && (
                                                <ImageGatsby
                                                    {...searchResult.w7}
                                                    width={80}
                                                    height={80}
                                                />
                                            )}
                                        </div>
                                        <div className={style.search__resultItem__description}>
                                            <h4 className="text-md text-semibold">{searchResult.w2}</h4>
                                            {searchResult.w5 && (
                                                <p className="text-xs text-medium text-darker uppercase">{buildDate(searchResult.w5)}</p>
                                            )}
                                        </div>
                                    </a>
                                </li>
                            ))}
                            {searchResults.length > 10 &&
                                <li className={style.search__resultShowAll}>
                                    <a className="cta-green-ter" target="_self" href={`/sok/?q=${q}`}>Visa alla resultat</a>
                                </li>
                            }
                        </ul>
                    }
                </div>
            }

        </div>
    );
};

export default SearchBox;
