// extracted by mini-css-extract-plugin
export var search__resultItem = "searchBoxSuggest-module--search__resultItem--Cj5Rd";
export var search__resultItem__description = "searchBoxSuggest-module--search__resultItem__description--rWMj1";
export var search__resultItem__image = "searchBoxSuggest-module--search__resultItem__image--JAM25";
export var search__resultShowAll = "searchBoxSuggest-module--search__resultShowAll--NoksO";
export var search__result__item = "searchBoxSuggest-module--search__result__item--QlT-w";
export var search__resultsList = "searchBoxSuggest-module--search__resultsList--DPirm";
export var search__resultsLoader = "searchBoxSuggest-module--search__resultsLoader--1PNcr";
export var search__resultsWrapper = "searchBoxSuggest-module--search__resultsWrapper--ZE2do";
export var search__searchField = "searchBoxSuggest-module--search__searchField--fZkls";
export var search__searchField__inputContainer = "searchBoxSuggest-module--search__searchField__inputContainer--rL4jp";
export var search__searchField__submit = "searchBoxSuggest-module--search__searchField__submit--8Zzh7";
export var search__searchMeta = "searchBoxSuggest-module--search__searchMeta--wL8d2";